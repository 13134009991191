::placeholder {
  /* font-weight: bold; */
}

.bg {
  background: transparent url("../images/Group\ 107537.png") 50% 50% no-repeat;
  /* background-image: url('../images/Group\ 107537.png'); */

  opacity: 1;
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.body {
  margin-top: 8em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.body-img {
  background: transparent url("../images/Group\ 107534.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  /* position: relative; */
  /* Layout Properties */
  width: 549px;
  height: 478px;
}

.form {
  /* Layout Properties */

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  width: 410px;
  height: 440px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 14px 19px #95a2a966;
  border-radius: 15px;
  opacity: 1;
}

.form-logo {
  width: 126px;
  height: 81px;
  background: transparent url("../images/HDFC_logo.png") 0% 0% no-repeat padding-box;
  /* width: 200px;
  height: 90px;
  background: transparent url("../images/ACC_logo.png") 0% 0% no-repeat padding-box; */    
  opacity: 1;
}

.form-input {
  height: 3em;
  width: 83%;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
}

.form-button {
  height: 3em;
  width: 83%;
  color: white;
  background: #ed1c24 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ff57155c;
  border-color: transparent;
  border-radius: 5px;
  opacity: 1;
}

.eye-pass {
  background: transparent url("../images/Icon\ ionic-md-eye-off.png") 0% 0%
    no-repeat padding-box;
  position: absolute;
  height: 2em;
  width: 2em;
  opacity: 1;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 600px) {
  .bg {
    overflow-y: hidden;
  }
  .body {
    display: flex;
    flex-direction: column;
  }

  .body-img {
    display: none;
  }

  .form {
    justify-content: space-evenly;
    align-items: center;

    width: 90vw;
    height: 80vh;
    overflow: hidden;
  }
}

@media only screen and (max-width: 941px) and (min-width: 600px) {
  .bg {
    overflow-y: hidden;
  }
  .body-img {
    display: none;
  }

  .form {
    width: 80vw;
  }
}
