.content{
    /* margin-top: 50px; */
    width: 100%;
    height: 100%;
    margin: 42px 0px;
}
.border{
    color: #00000029;
}
.border1{
    padding: 0px;
}
.centre{
    padding: 40px;
}
.headerline{
    font-size: 1em;
    display: flex;
    justify-content: space-between;
}
.tablehead{
    background-color: #005E9C;
    color: white;
    border-radius: 5px;
}
.ctc-table{
    padding: 0px 0px 0px 0px;
}
.table{
    border-radius: 5px;
}
.table-edit-icon{
    padding: 0px 10px 0px 14px;
    cursor: pointer;
}
.table-delete-delete{
    padding: 0px 0px 0px 0px;
    cursor: pointer;
}
.table-data-icon{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.table1{
    /* height: 240px; */
}
.addRole-table-head{
    font-size: 12px;
}
.addRole-table-data{
    font-size: 12px;
}
.addrolebtn{
    padding-right: 4px;
}
.table td, .table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-head{
    font-size: 12px;
}
.table-data {
    word-wrap: break-word;
    text-align: center;
}
@media only screen and (max-width: 320px) {
    .table-head{
        font-size: 10px;
    }
    .table-data{
        font-size: 10px;
    }
    .addRole-table-head{
        font-size: 10px;
    }
    .addRole-table-data{
        font-size: 10px;
    }
}


/* inputfield.css */

.search-container {
    height: 40px;
    max-height:2.5em;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid #005E9C;
    padding: 5px;
    border-radius: 5px;
    width: 100%; /* Take up the full width by default */
    max-width: 200px; /* Limit the width for larger screens */
    margin: 0 auto; /* Center align the container horizontally */
    margin-top: 0;
    margin-bottom: 1em; /* Add some margin to the bottom */
    margin-right: 2em;
  }
  
  input[type="text"] {
    flex: 1;
    border: none;
    outline: none;
    width: 98%; /* Take up the full width of the container */
  }
   
    input[type="text"]::placeholder {
        color: #000000;
        font-weight: bold !important;

      }
  .search-icon {
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    margin-left: 10px; /* Add some space between the input and icon */
  }
  
  .searchContainer_main{
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .bgcolor_serchIcon{
    background-color: #005E9C;
    border: 1px solid #005E9C;
    border-radius: 5px;
    color: #dee2e6;
  }

  @media only screen and (min-width: 300px) and (max-width: 768px){
.headerline {
    font-size: 1em;
}
}