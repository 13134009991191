.master {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

@media only screen and (max-width: 500px) {
    .windowContainer {
        grid-template-columns: 1fr;
        grid-template-areas: "headerContainer" "windowInnerContainer";
    }
}

@media only screen and (min-width: 501px) and (max-width: 1200px) {
    .windowContainer {
        grid-template-columns: auto 1fr;
        grid-template-areas: "sidebarContainer headerContainer" "sidebarContainer windowInnerContainer";
    }
}

@media only screen and (min-width: 1201px) {
    .windowContainer {
        grid-template-columns: auto 1fr;
        grid-template-areas: "sidebarContainer headerContainer" "sidebarContainer windowInnerContainer";
    }
}

.windowContainer {
    display: grid;
    grid-template-rows: auto 1fr;
}

.windowInnerContainer {
    display: grid;
    grid-area: windowInnerContainer;
}