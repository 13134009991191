.content {
  width: 100%;
  height: 100%;
  margin: 42px 0px;
}

.loading-blur {
  z-index: 1;
  width: 100%;
  height: 100%;
  filter: blur(4px);
}

.atoz {
  /* position: relative; */
  /* margin-left: 20em; */
  /* left: 20em; */
  cursor: pointer;
}

.border {
  color: #00000029;
}
.table td,
.table th {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.border1 {
  padding: 0px;
}
.centre {
  
  /* padding: 10px; commented for UI alignment changes */

}
.headerline {
  display: flex;
  justify-content: space-evenly;
}
.tablehead {
  background-color: #005e9c;
  color: white;
  border-radius: 5px;
}
.ctc-table {
  padding: 0.5rem 0px 0px 0px;
}
.table {
  border-radius: 5px;
}
.table-edit-icon {
  padding: 0px 10px 0px 14px;
  cursor: pointer;
}
.table-delete-delete {
  padding: 0px 0px 0px 0px;
  cursor: pointer;
}
.table-data-icon {
  display: flex;
}
.table1 {
  /* height: 240px; */
}
.table-head {
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
}
/* CSS to add a bottom border radius to the last row */
.table.table-bordered {
  border-collapse: collapse;
  width: 100%;
}

.table-body tr:last-child {
  border-bottom-left-radius: 12px; /* Adjust as needed */
  border-bottom-right-radius: 12px; /* Adjust as needed */
  border: 1px solid #dee2e6;
}

/* Add a background color to the last row if needed */
.table-body tr:last-child {
  background-color: #f5f5f5; /* Adjust the background color as needed */
}

/* Remove the border-radius from other rows */
.table-body tr:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-data {
  font-size: 12px;
  max-width: 100px;
}
.table-data-small {
  font-size: 12px;
  /* max-width: 30px; */
  width: 10vh;
}
.addrolebtn {
  padding-right: 4px;
}
.table_body {
  max-width: 100px;
}
.table_size {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  /* max-width: 100px; */
}
.table_size-small {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 320px) {
  .table-head {
    font-size: 10px;
  }
  .table-data {
    font-size: 10px;
  }
  .addRole-table-head {
    font-size: 10px;
  }
  .addRole-table-data {
    font-size: 10px;
  }
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}
.pagination .active {
  background-color: #005E9C;
  color: #fff; 
  border-radius: 6px;
}
.pagination a.active {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 20px;  */
  z-index: 2; 
}
.nextButtonLink {
  color: #005E9C !important; 
  font-size: 16px; 
  font-weight: 700;
}
.previousButtonLink{
  font-size: 16px; 
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .atoz {
    position: relative;
    margin-left: 0em;
    margin: 1em;
  }
  .search-container{
 position: relative;
    margin-left: 0em;
    margin: 1em;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1199px) {
  .atoz {
    margin: 0em 0em 2em 3.5em;
  }
 

}

@media only screen and (min-width: 1200px) {
  .button-group {
    display: flex;
  }

  .atoz {
    /* position: relative; */
    /* width: 100%; */

    margin-right: 1em;
  }
  .search-container{
    margin-right: 2em;

  }
}

.headertag {
  font-weight: 600;
  font-size: 18px;
}


/* inputfield.css */

.search-container-callcenter {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #005E9C;
  padding: 5px;
  border-radius: 5px;
  width: 100%; /* Take up the full width by default */
  /* max-width: 400px; */
  max-width: 290px;
  margin: 0 auto; /* Center align the container horizontally */
  margin-top: 0;
  margin-bottom: 1em; /* Add some margin to the bottom */
  margin-right: 2em;
  background-color: white;
}

input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  width: 98%; /* Take up the full width of the container */
}

.search-icon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 5px; /* Add some space between the input and icon */
  margin-right: 5px;
}


/* Apply border-radius to the first and last th and td elements in the table */
table.table.table-bordered {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
}

/* Apply border-radius to the first th and td elements in the thead section */
table.table.table-bordered thead th:first-child
{
  border-top-left-radius: 5px; /* Adjust the radius as needed */
}

/* Apply border-radius to the last th and td elements in the thead section */
table.table.table-bordered thead th:last-child {
  border-top-right-radius: 5px; /* Adjust the radius as needed */
}


/* Apply border-radius to the bottom-left and bottom-right cells of the last row in the tbody */
table.table.table-bordered tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px; /* Adjust the radius as needed */
}

table.table.table-bordered tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px; /* Adjust the radius as needed */
}


/* Apply custom font to the row data in the table */
table.table.table-bordered tbody td {
  font-family:  sans-serif; /* Replace "Your Custom Font" with the actual font name */
  font-size: 12px; /* Adjust the font size as needed */
  font-weight: 400;
  color: #000000;
}
.bgcolor_serchIcon{
  background-color: #005E9C;
  border: 1px solid #005E9C;
  border-radius: 5px;
  color: #dee2e6;
}


@media only screen and (min-width: 300px) and (max-width: 768px){
  .headerline {
      font-size: 1em;
  }
  }