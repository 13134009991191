
.coloe {
  /* background-color: #f3f3f3 !important; */
  color: #000000;
  /* font-weight: bold; */
  box-shadow: #0000001a;
  border-color: #ccc6ff !important;
}
.coloe::placeholder {
  color: #000000;
  font-weight: bold;
}

.filterAppointment > .react-datepicker__tab-loop > .react-datepicker-popper {
  z-index: 1 !important;
}
.filterAppointmentStart {
  /* border: 1px solid black !important ; //old color code foe calender in download call records */
  /* border: 1px solid #365feb !important; */
  border: 1px solid #005E9C !important ;
  padding: 5px !important;
  border-radius: 5px !important;
  background-color: white !important;
  margin-right: 15px;
}
.filterAppointmentStart
  > .react-datepicker__tab-loop
  > .react-datepicker-popper {
  z-index: 1 !important;
}
.filterAppointmentEnd > .react-datepicker__tab-loop > .react-datepicker-popper {
  z-index: 1 !important;
  left: -45px !important;
}
/* calendar */
/* for full width of inputbox wrt parent*/
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  padding: 0px 8px 0px 0px;
  color: #000000 !important;
  font-weight: bold !important;
}

/* .calEndDateIcon {
  position: relative;
  top: -37px;
  right: 25px;
  float: right;
  color: red;
} */

.filterContainer {
  display: flex !important;
  padding-left: 5em;
  /* margin-left: 8em; */
  /* align-items: center !important; */
  /* align-content: center; */
  background-color: white;
  /* height: 10vh;
  width: 100vw; */
}

@media only screen and (max-width: 774px) {
    .filterContainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 0em;
      padding-left: 0em;
    }
}