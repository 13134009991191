.size {
    width: 83%;
    
}

.focused:focus {
    border: 2px solid black;
}

.fab{
    position: absolute;
    left: 80%;
    /* margin-top: 10px; */
    font-size: 14px;
    cursor: pointer;
    color: #999;
    /* z-index: 4; */
    background-color: transparent;
}
.fab.active{
    color: dodgerblue;
    background-color: transparent;
}
.masterfab{
    position: absolute;
    left: 85%;
    margin-top: 11px;
    font-size: 14px;
    cursor: pointer;
    color: #999;
    background-color: transparent;
}
.masterfab.active{
    color: dodgerblue;
    background-color: transparent;
}



@media only screen and (max-width: 500px) {
    .aaaa{
        margin-top: 110px !important;
        /* margin-right: 200px !important; */
    }
}

@media only screen and (min-width: 501px) and (max-width: 1200px) {
    .aaaa{
        margin-top: 120px !important;
        padding-right: 200px !important;
    }
}

@media only screen and (min-width: 1201px) {
    .aaaa{
        margin-top: 130px !important;
        padding-left: 30px !important;
    }

}
.aaaa{
    margin-top: 230px;
    
        /* padding-left: 1000px !important;  */
}