.modalBackground {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.modalContainer {
  width: 580px;
  height: 550px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainerRoleError {
  width: 580px;
  height: 580px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainerCallCentre {
  width: 580px;
  height: 240px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainerCallCentreError {
  width: 580px;
  height: 260px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainerUserMaster {
  width: 580px;
  height: 415px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainerUserMasterError {
  width: 580px;
  height: 480px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  /* display: inline-block; */
  /* text-align: center; */
  margin-top: 10px;
}

/* .titleCloseBtn {
  display: flex;
  justify-content: flex-end;
} */

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainerbody {
  /* flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center; */
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: transparent;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

/* .add-role-modal{
  align-self: start;
} */
.add-role-modal-header{
  display: flex;
}

.modeltextbox{
  padding: 10px;
}

.aligntextbox{
  width: 100%;
}

.aligntextbox1{
  width: 50%;
}

.access{
  padding: 0px 0px 0px 10px;
    font-size: 13px;
}

