.content {
    margin-top: 50px;
}

.light-button {
    height: 40px;
    border-radius: 4px;
    font-size: 13px;
    color: red;
    width: 115px;
    background-color: #ffff;
    border:  1px solid red;
}

.clear-date {
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    color: rgb(0, 0, 0);
    width: 115px;
    background-color: #ffff;
    border: 0px solid black;
}

.content{
    /* margin-top: 50px; */
    width: 100%;
    height: 100%;
}
.border{
    color: #00000029;
}
.border1{
    padding: 0px;
}
.centre{
    padding: 40px;
}

.table-data {
    word-wrap: break-word;
}
.headerline{
    display: flex;
    justify-content: space-between;
}
.tablehead{
    background-color: #005E9C;
    color: white;
    border-radius: 5px;
}
.ctc-table{
    padding: 0px 0px 0px 0px;
}
.table{
    border-radius: 5px;
}
.table-edit-icon{
    padding: 0px 10px 0px 14px;
    cursor: pointer;
}
.table-delete-delete{
    padding: 0px 0px 0px 0px;
    cursor: pointer;
}
.table-data-icon{
    display: flex;
}

.table-filter{
  padding-left: 1.5em;
  cursor: pointer;
}


.pagination-style {
    border-color: transparent;
   
}
.clearDate{
    font-size: 13px;
    padding: 10px;
}
.header-buttons {
    display: flex;
    /* flex-direction: row;
    margin: 1em;
    flex-wrap: wrap;
    justify-content: center; */
    
}
.datePicker-Input{
    width: 100px;
}
.reportDropdown{
    width: 100px;
    padding: 0px 10px 0px 0px;
}
.reportDropdown .selectAddNew {
    height: 100%;
}
.reportDropdown .selectAddNew .custom-style-input-regular{
    margin-top: 0.3rem;
    float: none;
    height: 16px;
    border: 0px solid black;
    width: 100%;
    color: black;
    font-size: 17px;
}

.calendar{
    border: 1px solid #ED1C24;
    border-radius: 5px;
    padding: 4px;    
}
.searchButton{
    border: 1px solid #ED1C24 !important;
    border-radius: 5px;
    padding: 4px; 
    font-weight: bold;
    color: black;
}
.searchButton.active {
    border: 1px solid #ED1C24 !important;
    border-radius: 5px;
    padding: 4px; 
    font-weight: bold;
    color: black;
  }
  .searchButton input:focus {
    border: 1px solid #ED1C24 !important;
    border-radius: 5px;
    padding: 4px; 
    font-weight: bold;
    color: black;
}
.nextButtonLink {
    color: #005E9C !important; 
    font-size: 16px; 
    font-weight: 700;
  }
  .previousButtonLink{
    font-size: 16px; 
    font-weight: 700;
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 20px;  */
  }
@media only screen and (max-width: 774px) {
.content {
    overflow-x: hidden;
}

    .table-head{
        font-size: 10px;
    }
    .table-data{
        font-size: 10px;
    }

    .headerline {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5em;
        
    }

    .header-buttons {
        display: flex;
        flex-direction: row;
        margin: 1em;
        flex-wrap: wrap;
        justify-content: center;
        
    }

    .light-button {
        margin-bottom: 1em;
        border: 0px solid black;
    }

    .clear-date {
        margin-bottom: 1em;
        border: 0px solid black;
    }


    
}

@media only screen and (min-width: 800px) {
    
    .date-picker {
        display: flex;
        margin-left: 25em;
        margin-top: 0.3em;
    }

}

@media only screen and (max-width: 460px) {
    .headerline {
        display: flex;
        flex-direction: column;
    }

    .button-set {
        display: flex;
        justify-content: center;
        margin-top: 1em;
    }

    .reportDropdown{
        width: 150px;
        height: 36px;
        margin: 5px;
    }
    .calendar{
        border: 1px solid #ED1C24;
        border-radius: 5px;
        padding: 4px;
        margin: 5px;
        height: 45px;    
    }
}


@media only screen and (min-width: 461px) and (max-width:768px) {
    
    .headerline {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;
        
    }

    .reportDropdown{
        width: 150px;
        height: 36px;
        margin: 5px;
    }
    .calendar{
        border: 1px solid #ED1C24;
        border-radius: 5px;
        padding: 4px;
        margin: 5px;
        height: 45px;    
    }

    .button-set {
        margin-top: 1em;
    }
}

.download {
    /* Layout Properties */
top: 259px;
left: 1289px;
width: 25px;
height: 23px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
border: 1px solid var(--unnamed-color-ed1c24);
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #ED1C24;
border-radius: 4px;
opacity: 1;
}
.calendar-border {
    border-color:1px solid #005E9C!important;
}

/* @media only screen and (max-width: 1200px) {
    
    .tabelButton {
        font-size: 0.5em;
        margin-bottom: 2px;
    }

} */
.download_img{
    border-radius: 3px;
    background-color: #005E9C;
    width: 2em;
    height: 2em;
    padding: 3px;
    border: 1 px solid;
    border: 1px solid #005E9C;
    @media (max-width: 1200px) and (min-width:375px) {
        width: 1.5em;
        height: 1.5em;
      }

}
.last_row{
    text-align: center;
 
}
.tabelButton {
    height: 2em;
    font-size: 1em;
    margin-right: 0.5em;
    animation: none;
    transition: filter 0.3s ease; /* Add a transition for smooth effect */
    padding: 5px 10px; /* Adjust the padding as needed */
    width: auto; /* Allow the button to adjust its width based on content */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; 
 border: 1px solid #005E9C; /* Border color (adjust as needed) */
 border-radius: 5px; /* Rounded corners (adjust as needed) */
 background-color: white; /* Button background color (adjust as needed) */
 color: #005E9C; /* Text color */
    @media (max-width: 1200px) and (min-width:375px){
        padding: 3px 6px; /* Adjust padding for smaller screens */
        font-size: 0.8em; /* Adjust font size for smaller screens */
        margin-bottom: 5px;

      }
      @media (max-width: 1440px){
        margin-bottom: 5px;

      }

  }
  
  /* Apply blur effect on button hover */
  .tabelButton:hover {
    filter: blur(0px); /* Adjust the blur amount as needed */
  }
  
  /* Apply blur effect on button click (active) */
  .tabelButton:active {
    filter: blur(0px); /* Adjust the blur amount as needed */
  }
  

/* Custom styles for the modal */
.modal {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .modal-content {
    border-radius: 10px;
  }
  
  .modal-header {
    background-color: #005E9C; /* Header background color */
    color: #fff; /* Header text color */
    border-bottom: none; /* Remove border at the bottom of the header */
  }
  
  .modal-title {
    font-size: 1.25rem; /* Title font size */
  }
  
  .status-text {
    font-size: 1rem; /* Status text font size */
  }
  
  .modal-footer {
    border-top: none; /* Remove border at the top of the footer */
  }
  
  /* Custom styles for the Close button */
  .btn-secondary {
    background-color: #005E9C; /* Button background color */
    color: #fff; /* Button text color */
  }
  
  .btn-secondary:hover {
    background-color: #005E9C; /* Hover color */
  }

  /* inputfield.css */

.search-container {
    height: 2.5em;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #005E9C;
    padding: 5px;
    border-radius: 5px;
    width: 100%; /* Take up the full width by default */
    max-width: 200px; /* Limit the width for larger screens */
    margin: 0 auto; /* Center align the container horizontally */
    margin-top: 0;
    margin-bottom: 1em; /* Add some margin to the bottom */
    margin-right: 0.5em;
  }
  
  input[type="text"] {
    flex: 1;
    border: none;
    outline: none;
    width: 98%; /* Take up the full width of the container */
  }
   
    input[type="text"]::placeholder {
        color: #000000; 
        font-weight: bold !important;

      }
  .search-icon {
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    margin-left: 10px; /* Add some space between the input and icon */
  }
  
  .bgcolor_serchIcon{
    background-color: #005E9C;
    border: 1px solid #005E9C;
    border-radius: 5px;
    color: #dee2e6;
  }
  @media only screen and (min-width: 300px) and (max-width: 768px){
    .headerline {
        font-size: 1em;
    }
    }
