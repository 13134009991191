.sidebarContainer {
  z-index: 6;
}

.list-buttons:focus {
  background-color: red;
}

/* ICONS  */
.metadata-records-icon{
  background: transparent 
  url("../../images/meta-data-records.png") 0% 0% no-repeat padding-box;
    background-size: contain;
    height: 1em;
    width: 1em;
    /* background-color: ; */
    opacity: 1;

}

.call-records-icon {
  background: transparent
    url("../../images/Call\ record\ icon.png") 0% 0% no-repeat
    padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  /* background-color: ; */
  opacity: 1;
}

.downloadcall-records-icon {
  background: transparent url("../../images/Download\ Call\ Record.png") 0% 0% no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  /* background-color: ; */
  opacity: 1;

}

.down-records-icon {
  background: transparent url("./../../images/Icon\ material-chat.png") 0% 0%
    no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 1;
}

.reports-icon {
  background: transparent url("./../../images/Icon\ open-document.png") 0% 0%
    no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 1;
}

.roll-manage-icon {
  background: transparent url("./../../images/Icon\ awesome-users.png") 0% 0%
    no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 1;
}

.user-master-icon {
  background: transparent url("./../../images/Icon\ awesome-user-cog.png") 0% 0%
    no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 1;
}

.call-centre-icon {
  background: transparent url("./../../images/Icon\ material-call.png") 0% 0%
    no-repeat padding-box;
  background-size: contain;
  height: 1em;
  width: 1em;
  opacity: 1;
}

.menu-link {
  font-size: 18px;
  margin-left: 0px !important;
}
.menu-icon {
  display: none;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 980px) {
  .sidebarContainer {
    display: none;
    width: 46px;
    height: 100%;
  }
}

@media only screen and (min-width: 970px) and (max-width: 1200px) {
  .sidebarContainer {
    display: grid;
    grid-template-rows: 15%;
    height: 100vh;
    /* width: 30%; */
    min-width: 30%;
  }

  .list {
    margin-top: 1em;
  }
}

.activeNav {
  /* background-color: red !important; */
  background-color: #ED1C24 !important;
}

@media only screen and (min-width: 1201px) {
  .sidebarContainer {
    display: grid;

    min-width: 20%;
    height: 100vh;
  }
}

.sidebarContainer {
  /* grid-area: sidebarContainer; */
  background: #005e9c 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  color: white;
}

.sidebarLogo {
  background: transparent url(".././../images/HDFC_logo.svg") 50% 50% no-repeat
    padding-box;
  /* background: transparent url('.././../images/ACC_logo.png') 50% 50% no-repeat padding-box; */
  border: 1px solid #005e9c;
  opacity: 1;
  position: relative;
  display: grid;
  align-items: center;
  padding: 0 8%;
  background-color: #ffffff;
  height: 7em;
  width: 100%;
}

a:hover {
  text-decoration: none;
}

.list {
  display: grid;
  margin-bottom: 20em;
  padding: 0;
  grid-template-rows: repeat(10, 50px);
}

.list button {
  padding: 0;
  font-family: "Open Sans";
  font-size: 15px;
  padding-top: 1em;
  letter-spacing: 0px;
  padding-left: 1em;
  background-color: transparent;
  border: none;
  color: white;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 10px;
}

/* .list button:hover { 
    margin: 0;
} */
/* 
.list button:hover::before {
    content: "";
    margin: 0;
    background-color: #ffffff;
    width: 5px;
    height: 100%;
} */
