.newtextLabel123 {
    height: 100%;
    padding: 0px 0px 0px 13px;
    /* border: 1px solid #ED1C24;old color code  */
    /* border: 1px solid #365feb; */
    border: 1px solid #005E9C;
    border-radius: 3px;
    background: white;
    font-size: 11px;
    margin-right: 10px;
}
.custom-style-input-regular123 {
    margin-top: 5px;
    border: 0px;
    width: 90%;
    font-weight: bold;
    font-size: 14px;
    padding: 0px;
}
.textLabel input:focus {
    border: 0px solid transparent !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: 0 0 10px transparent !important;
    padding: 0px !important;
}
.textLabel input{
    padding: 0px !important;
}
.disabledStyle {
    /* background: #e9ecef; */
    background: #ED1C24;
}
.form-control12:focus {
    color: black;
    background-color: white;
    border-color: white;
    outline: 0;
    box-shadow: 0 0 0 0.2rem white;
}