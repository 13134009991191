.newtextLabel{
  /* height: 27px; */
  height: 30px;
  padding: 0px 0px 0px 13px;
  /* border: 1px solid #ed1c24; */
  border: 1px solid #365feb;
  border-radius: 3px;
  background:#fff;
  font-size: 11px;
}
.buisnesstestLabel {
  /* height: 27px; */
  height: 40px;
  padding: 0px 0px 0px 13px;
  /* border: 1px solid #ed1c24; */
  border: 1px solid #005E9C;
  border-radius: 3px;
  background: #fff;
  font-size: 11px;
}
.metadataTextBox{
  /* height: 40px; */
  border: 1px solid #365feb;
    border-radius: 3px;
  font-size: 14px;
}
.custom-style-input-regular {
  font-size: 14px;
  font-weight: 600;
  color: #6c757d !important; 
   font-family: "Open Sans", sans-serif; 
}
.custom-style-input-regular {
  /* margin-top: 0rem; //old code */
  margin-top: 0rem;
  border: 0px;
  height: 25px;
  width: 100%;
  /* padding: 0px; */
    padding: 8px;
    padding-left: 30px;
    
}

.textLabel input:focus {
  border: 0px solid transparent !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: 0 0 10px transparent !important;
  padding: 0px !important;
}
.textLabel input {
  padding: 0px !important;
}
.disabledStyle {
  /* background: #e9ecef; */
  background: #ed1c24;
}
