.standardEntryBox {
    display: grid;
    grid-template-rows: repeat(2, minmax(19px, 25px));
    align-self: flex-start;
}

.labelDiv {
    text-align: left;
}

.entrylabel {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
}

.standardEntry {
    border-width: 0px;
    border-bottom-width: 2px;
    outline: none;
    padding-left: 7px;
    padding-bottom: 5px;
    width: 100%;
    font-size: 18px;
    background-color: transparent;
}

::placeholder {
    font-size: 13px;
    color: #43425D;
}